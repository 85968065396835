export const INCREMENT = 'INCREMENT';
export const DECREMENT = 'DECREMENT';
export const ADD = 'ADD';
export const SUBTRACT = 'SUBTRACT';
export const STORE_RESULT = 'STORE_RESULT';
export const DELETE_RESULT = 'DELETE_RESULT';

///////////////////////////
//////////////////////////
//Insumos
///////////////////////////
//////////////////////////
export const GUARDA_LISTADO_INSUMOS = 'GUARDA_LISTADO_INSUMOS';
